import React from 'react';
import TextWrapper from './termsofservicecontainer.style';
import Container from 'common/components/UI/Container';

const TermsOfServiceContainer: React.FC = () => {
  return (
    <Container>
      <TextWrapper className="primary-text-color">
        <div className="flex flex-row items-center">
          <div className="flex flex-col justify-center items-center">
            <h1>Notice and Disclaimer</h1>
          </div>
        </div>
        <div className="flex pt-10">
          <div className="flex flex-col">
            <p>NOTICE AND DISCLAIMER PLEASE READ THE ENTIRETY OF THIS &quot;NOTICE AND DISCLAIMER&quot; SECTION CAREFULLY. NOTHING HEREIN CONSTITUTES LEGAL, FINANCIAL, BUSINESS OR TAX ADVICE AND YOU SHOULD CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY IN CONNECTION HEREWITH. NEITHER ICHI DAO (THE <strong>DAO</strong>), ANY OF THE PROJECT TEAM MEMBERS (THE <strong>ICHI CORE TEAM</strong>) WHO HAVE WORKED ON THE ICHI PLATFORM (AS DEFINED HEREIN) OR PROJECT TO DEVELOP THE ICHI PLATFORM IN ANY WAY WHATSOEVER, ANY DISTRIBUTOR/VENDOR OF ICHI TOKENS (THE <strong>DISTRIBUTOR</strong>), NOR ANY SERVICE PROVIDER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR INDIRECT DAMAGE OR LOSS WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH ACCESSING ANY TOKEN DOCUMENTATION RELATING TO ICHI (THE <strong>TOKEN DOCUMENTATION</strong>), THE WEBSITE AT HTTPS://WWW.ICHI.ORG/ (THE <strong>WEBSITE</strong>) OR ANY OTHER WEBSITES OR MATERIALS PUBLISHED BY THE DAO. </p>
            <p><strong>Project purpose:</strong> You agree that you are acquiring ICHI to participate in the ICHI platform and to obtain services on the ecosystem thereon. The DAO, the Distributor and their respective affiliates would develop and contribute to the underlying source code for the ICHI platform. The DAO is acting solely as an arms' length third party in relation to the ICHI distribution, and not in the capacity as a financial advisor or fiduciary of any person with regard to the distribution of ICHI. </p>
            <p><strong>Nature of the Token Documentation:</strong> The Token Documentation and the Website are intended for general informational purposes only and do not constitute a prospectus, an offer document, an offer of securities, a solicitation for investment, or any offer to sell any product, item, or asset (whether digital or otherwise). The information herein may not be exhaustive and does not imply any element of a contractual relationship. There is no assurance as to the accuracy or completeness of such information and no representation, warranty or undertaking is or purported to be provided as to the accuracy or completeness of such information. Where the Token Documentation or the Website includes information that has been obtained from third party sources, the DAO, the Distributor, their respective affiliates and/or the ICHI Core team have not independently verified the accuracy or completeness of such information. Further, you acknowledge that circumstances may change and that the Token Documentation or the Website may become outdated as a result; and neither the DAO nor the Distributor is under any obligation to update or correct this document in connection therewith. </p>
            <p><strong>Token Documentation:</strong> Nothing in the Token Documentation or the Website constitutes any offer by the DAO, the Distributor, or the ICHI Core team to sell any ICHI (as defined herein) nor shall it or any part of it nor the fact of its presentation form the basis of, or be relied upon in connection with, any contract or investment decision. Nothing contained in the Token Documentation or the Website is or may be relied upon as a promise, representation or undertaking as to the future performance of the ICHI platform. The agreement between the Distributor (or any third party) and you, in relation to any distribution or transfer of ICHI, is to be governed only by the separate terms and conditions of such agreement. </p>
            <p>The information set out in the Token Documentation and the Website is for community discussion only and is not legally binding. No person is bound to enter into any contract or binding legal commitment in relation to the acquisition of ICHI, and no digital asset or other form of payment is to be accepted on the basis of the Token Documentation or the Website. The agreement for distribution of ICHI and/or continued holding of ICHI shall be governed by a separate set of Terms and Conditions or Token Distribution Agreement (as the case may be) setting out the terms of such distribution and/or continued holding of ICHI (the Terms and Conditions), which shall be separately provided to you or made available on the Website. The Terms and Conditions must be read together with the Token Documentation. In the event of any inconsistencies between the Terms and Conditions and the Token Documentation or the Website, the Terms and Conditions shall prevail. </p>
            <p><strong>ICHI Token:</strong> The native digital cryptographically-secured utility token of the ICHI platform (ICHI) is a transferable representation of attributed functions specified in the protocol/code of the ICHI platform, and which is designed to be used solely as an interoperable utility token on the platform. ICHI is a functional utility token which will be used as the native governance token and economic incentives which will be distributed to encourage users to contribute to and maintain the ecosystem on the ICHI platform, thereby creating a win-win system where every participant is fairly compensated for its efforts. ICHI is an integral and indispensable part of the ICHI platform, because without ICHI, there would be no incentive for users to expend resources to participate in activities or provide services for the benefit of the entire ecosystem on the ICHI platform. Given that additional ICHI will be awarded to a user based only on its actual usage, activity and contribution on the ICHI platform, users of the ICHI platform and/or holders of ICHI which did not actively participate will not receive any ICHI incentives. </p>
            <p>Save as expressly described in relation to limited voting rights, ICHI does not in any way represent any shareholding, participation, right, title, or interest in the DAO (save as expressly described in relation to limited voting rights), the Distributor, their respective affiliates, or any other company, enterprise or undertaking, nor will ICHI entitle token holders to any promise of fees, dividends, revenue, profits or investment returns, and are not intended to constitute securities in Singapore or any relevant jurisdiction. ICHI may only be utilised on the ICHI platform, and ownership of ICHI carries no rights, express or implied, other than the right to use ICHI as a means to enable usage of and interaction within the ICHI platform. </p>
            <p>ICHI are designed to be utilised, and that is the goal of the ICHI distribution. In fact, the project to develop the ICHI platform would fail if all ICHI holders simply held onto their ICHI and did nothing with it. In particular, it is highlighted that ICHI: </p>
            <ol type="a">
              <li>does not have any tangible or physical manifestation, and does not have any intrinsic value (nor does any person make any representation or give any commitment as to its value); </li>
              <li>is non-refundable and cannot be exchanged for cash (or its equivalent value in any other digital asset) or any payment obligation by the DAO, the Distributor or any of their respective affiliates; </li>
              <li>does not represent or confer on the token holder any right of any form with respect to the DAO, the Distributor (or any of their respective affiliates), or its revenues or assets, including without limitation any right to receive future dividends, revenue, shares, ownership right or stake, share or security, any voting (save as expressly described in relation to limited voting rights), distribution, redemption, liquidation, proprietary (including all forms of intellectual property or licence rights), right to receive accounts, financial statements or other financial data, the right to requisition or participate in shareholder meetings, the right to nominate a director, or other financial or legal rights or equivalent rights, or intellectual property rights or any other form of participation in or relating to the ICHI platform, the DAO (save as expressly described in relation to limited voting rights), the Distributor and/or their service providers; </li>
              <li>is not intended to represent any rights under a contract for differences or under any other contract the purpose or pretended purpose of which is to secure a profit or avoid a loss; </li>
              <li>is not intended to be a representation of money (including electronic money), security, commodity, bond, debt instrument, unit in a collective investment scheme or any other kind of financial instrument or investment; </li>
              <li>is not a loan to the DAO, the Distributor or any of their respective affiliates, is not intended to represent a debt owed by the DAO, the Distributor or any of their respective affiliates, and there is no expectation of profit; and </li>
              <li>does not provide the token holder with any ownership or other interest in the DAO, the Distributor or any of their respective affiliates (save as expressly described in relation to limited voting rights). </li>
            </ol>
            <p>Notwithstanding the ICHI distribution, users have no economic or legal right over or beneficial interest in the assets of the DAO, the Distributor, or any of their affiliates after the token distribution. </p>
            <p>To the extent a secondary market or exchange for trading ICHI does develop, it would be run and operated wholly independently of the DAO, the Distributor, the distribution of ICHI and the ICHI platform. Neither the DAO nor the Distributor will create such secondary markets nor will either entity act as an exchange for ICHI. </p>
            <p><strong>Deemed Representations and Warranties: </strong>By accessing the Token Documentation or the Website (or any part thereof), you shall be deemed to represent and warrant to the DAO, the Distributor, their respective affiliates, and the ICHI Core team as follows: </p>
            <ol type="a">
              <li>in any decision to acquire any ICHI, you have shall not rely on any statement set out in the Token Documentation or the Website; </li>
              <li>you will and shall at your own expense ensure compliance with all laws, regulatory requirements and restrictions applicable to you (as the case may be); </li>
              <li>you acknowledge, understand and agree that ICHI may have no value, there is no guarantee or representation of value or liquidity for ICHI, and ICHI is not an investment product nor is it intended for any speculative investment whatsoever; </li>
              <li>none of the DAO, the Distributor, their respective affiliates, and/or the ICHI Core team members shall be responsible for or liable for the value of ICHI, the transferability and/or liquidity of ICHI and/or the availability of any market for ICHI through third parties or otherwise; and </li>
              <li>you acknowledge, understand and agree that you are not eligible to participate in the distribution of ICHI if you are a citizen, national, resident (tax or otherwise), domiciliary and/or green card holder of a geographic area or country (i) where it is likely that the distribution of ICHI would be construed as the sale of a security (howsoever named), financial service or investment product and/or (ii) where participation in token distributions is prohibited by applicable law, decree, regulation, treaty, or administrative act (including without limitation the United States of America and the People's Republic of China); and to this effect you agree to provide all such identity verification document when requested in order for the relevant checks to be carried out. </li>
            </ol>
            <p>The DAO, the Distributor and the ICHI Core team do not and do not purport to make, and hereby disclaims, all representations, warranties or undertaking to any entity or person (including without limitation warranties as to the accuracy, completeness, timeliness, or reliability of the contents of the Token Documentation or the Website, or any other materials published by the DAO or the Distributor). To the maximum extent permitted by law, the DAO, the Distributor, their respective affiliates and service providers shall not be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including, without limitation, any liability arising from default or negligence on the part of any of them, or any loss of revenue, income or profits, and loss of use or data) arising from the use of the Token Documentation or the Website, or any other materials published, or its contents (including without limitation any errors or omissions) or otherwise arising in connection with the same. Prospective acquirors of ICHI should carefully consider and evaluate all risks and uncertainties (including financial and legal risks and uncertainties) associated with the distribution of ICHI, the DAO, the Distributor and the ICHI Core team. </p>
            <p><strong>Informational purposes only:</strong> The information set out herein is only conceptual, and describes the future development goals for the ICHI platform to be developed. In particular, the project roadmap in the Token Documentation is being shared in order to outline some of the plans of the ICHI Core team, and is provided solely for <strong>INFORMATIONAL PURPOSES</strong> and does not constitute any binding commitment. Please do not rely on this information in deciding whether to participate in the token distribution because ultimately, the development, release, and timing of any products, features or functionality remains at the sole discretion of the DAO, the Distributor or their respective affiliates, and is subject to change. Further, the Token Documentation or the Website may be amended or replaced from time to time. There are no obligations to update the Token Documentation or the Website, or to provide recipients with access to any information beyond what is provided herein. </p>
            <p><strong>Regulatory approval:</strong> No regulatory authority has examined or approved, whether formally or informally, any of the information set out in the Token Documentation or the Website. No such action or assurance has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction. The publication, distribution or dissemination of the Token Documentation or the Website does not imply that the applicable laws, regulatory requirements or rules have been complied with. </p>
            <p><strong>Cautionary Note on forward-looking statements:</strong> All statements contained herein, statements made in press releases or in any place accessible by the public and oral statements that may be made by the DAO, the Distributor and/or the ICHI Core team, may constitute forward-looking statements (including statements regarding the intent, belief or current expectations with respect to market conditions, business strategy and plans, financial condition, specific provisions and risk management practices). You are cautioned not to place undue reliance on these forward-looking statements given that these statements involve known and unknown risks, uncertainties and other factors that may cause the actual future results to be materially different from that described by such forward-looking statements, and no independent third party has reviewed the reasonableness of any such statements or assumptions. These forward-looking statements are applicable only as of the date indicated in the Token Documentation, and the DAO, the Distributor as well as the ICHI Core team expressly disclaim any responsibility (whether express or implied) to release any revisions to these forward-looking statements to reflect events after such date. </p>
            <p><strong>References to companies and platforms:</strong> The use of any company and/or platform names or trademarks herein (save for those which relate to the DAO, the Distributor or their respective affiliates) does not imply any affiliation with, or endorsement by, any third party. References in the Token Documentation or the Website to specific companies and platforms are for illustrative purposes only. </p>
            <p><strong>English language:</strong> The Token Documentation and the Website may be translated into a language other than English for reference purpose only and in the event of conflict or ambiguity between the English language version and translated versions of the Token Documentation or the Website, the English language versions shall prevail. You acknowledge that you have read and understood the English language version of the Token Documentation and the Website. </p>
            <p><strong>No Distribution:</strong> No part of the Token Documentation or the Website is to be copied, reproduced, distributed or disseminated in any way without the prior written consent of the DAO or the Distributor. By attending any presentation on this Token Documentation or by accepting any hard or soft copy of the Token Documentation, you agree to be bound by the foregoing limitations. </p>   
          </div>
        </div>
      </TextWrapper>
    </Container>

    );
};

export default TermsOfServiceContainer;

